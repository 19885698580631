import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

const imageHero =
  "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FB2B%2Fbg-hero-terms.png?alt=media&token=8d1cc58d-344a-4505-a678-50252ee7575d";

export const Hero = styled.header`
  background-image: url("${imageHero}");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 47rem;
  position: relative;
  padding: 8rem 80px 0 80px;

  @media (max-width: 600px) {
    padding: 8rem 32px 0 32px;
  }

  @media (max-width: 425px) {
    padding: 8rem 16px 0 16px;
  }

  ::after {
    content: " ";
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${landingPalette.blue};
    opacity: 0.1;
  }

  h1 {
    font-size: 23px;
    line-height: 29px;
    color: white;
    font-family: ${fonts.SofiaProMedium};
    margin-bottom: 16px;
  }

  p {
    font-size: 5.8rem;
    line-height: 6.4rem;
    color: white;
    font-family: ${fonts.SofiaProBold};
    max-width: 66rem;

    @media (max-width: 425px) {
      font-size: 5.2rem;
    }

    @media (max-width: 375px) {
      font-size: 4.8rem;
    }
  }

  article {
    position: relative;
    z-index: 1;
  }
`;

export const InfoSection = styled.section`
  padding: 12rem 80px 8rem 80px;

  @media (max-width: 600px) {
    padding: 12rem 32px 8rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12rem 16px 8rem 16px;
  }

  .main-landing-container-column {
    align-items: unset;
  }

  .info-section {
    > h2 {
      font-family: ${fonts.SofiaProSemiBold};
      font-size: 2.8rem;
      line-height: 2.9rem;
      color: ${landingPalette.blue};
      margin-bottom: 1.6rem;
    }

    > p {
      font-family: ${fonts.sofiaProRegular};
      font-size: 2.3rem;
      line-height: 2.9rem;
      color: ${landingPalette.greyChevron};
      margin-bottom: 4rem;
    }
  }

  .info-section-list {
    display: flex;
    flex-direction: column;
    grid-gap: 4rem;

    h5 {
      font-family: ${fonts.SofiaProSemiBold};
      font-size: 1.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      margin-bottom: 2.4rem;
    }

    p {
      font-family: ${fonts.sofiaProRegular};
      font-size: 1.6rem;
      line-height: 2.8rem;
      color: ${landingPalette.greyChevron};
      margin-bottom: 4rem;
    }
  }
`;

export const ContactSection = styled.section`
  padding: 10rem 80px 8.5rem 80px;
  background-color: ${landingPalette.bgBlue};

  @media (max-width: 600px) {
    padding: 10rem 32px 8.5rem 32px;
  }

  @media (max-width: 425px) {
    padding: 10rem 16px 8.5rem 16px;
  }

  .contact-section {
    justify-content: space-between;
    grid-gap: 6rem;
    width: 100%;

    @media (max-width: 920px) {
      flex-direction: column;
      align-items: center;
    }
  }

  figure {
    max-width: 205px;
    width: 100%;

    img {
      width: 100%;
      object-fit: scale-down;
    }
  }

  article {
    max-width: 51.1rem;
    width: 100%;

    h5 {
      font-size: 3.8rem;
      line-height: 4.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.SofiaProSemiBold};
      margin-bottom: 3.2rem;
    }

    p {
      font-size: 2rem;
      line-height: 2.8rem;
      color: ${landingPalette.blue};
      font-family: ${fonts.sofiaProRegular};
      margin-bottom: 3.2rem;

      a {
        color: ${landingPalette.orange};
        text-decoration: none;
      }
    }

    .contact-button {
      border: none;
      outline: none;
      background-color: ${landingPalette.orange};
      border-radius: 21px;
      height: 44px;
      padding: 0 30px;
      font-family: ${fonts.SofiaProMedium};
      font-size: 1.6rem;
      color: white;
      line-height: 2rem;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }
  }
`;
