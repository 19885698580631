import React from "react";
import Layout from "../comps/reusable/Layout";
import { Link, graphql } from "gatsby";
import Seo from "../comps/reusable/Seo";
import { useTranslation } from "react-i18next";
import { Hero, InfoSection, ContactSection } from "../styles/pages/terms";
import OMG from "../images/home/omg.png";

const Terms = () => {
  const { t } = useTranslation("terms");
  return (
    <>
      <Seo
        title="Términos y Condiciones de nuestra plataforma de talento."
        description="Queremos que tengas la mejor experiencia posible en nuestra plataforma de talento."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/resources%2FSEO%2FTAG3.png?alt=media&token=1e0186ca-89d1-4e65-9386-e05e7e7c4ce7"
      />
      <Layout>
        <Hero>
          <div className="main-landing-container-row">
            <article>
              <h1>{t("heroTitle")}</h1>
              <p>{t("heroDesc")}</p>
            </article>
          </div>
        </Hero>
        <InfoSection>
          <div className="info-section main-landing-container-column">
            <h2>{t("heroTitle")}</h2>
            <p>{t("termsFirstDesc")}</p>
            <div className="info-section-list">
              <article>
                <h5>{t("termsSecondTitle")}</h5>
                <p>{t("termsSecondDesc")}</p>
              </article>
              <article>
                <h5>{t("termsThirdTitle")}</h5>
                <p>{t("termsThirdDesc")}</p>
              </article>
            </div>
          </div>
        </InfoSection>
        <ContactSection>
          <div className="contact-section main-landing-container-row">
            <article>
              <h5>{t("preFooterTitle")}</h5>
              <p>{t("preFooterDesc")}</p>
              <p>
                {t("contactMessage")}{" "}
                <a href="mailto:legal@lapieza.io">legal@lapieza.io</a>
              </p>
              <p>{t("contactMessage2")}</p>
              <Link className="contact-button" to="/contact">
                {t("contactTeam")}
              </Link>
            </article>
            <figure>
              <img src={OMG} alt="Neutralidad de carbono | LaPieza" />
            </figure>
          </div>
        </ContactSection>
      </Layout>
    </>
  );
};

export default Terms;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
